import { Page } from "../general/Page";

export const Home = () => {
  return (
    <Page title="Home">
      <div>
        <p>This is the homepage</p>
      </div>
    </Page>
  );
};
