import { Tag, TagId } from "./Tag";

import "./TagList.css";

export interface TagListProps {
  tagIds: TagId[];
  selectedTagId?: TagId;
  message?: string;
}

export const TagList = (props: TagListProps) => {
  return (
    <div className="Tag-List">
      <div className="Tag-List-Message">{props.message}</div>
      {props.tagIds.sort().map((_, ndx) => (
        <Tag key={ndx} id={_} selected={_ === props.selectedTagId} />
      ))}
      <hr />
    </div>
  );
};
