import { Page } from "../general/Page";

export const Contact = () => {
  return (
    <Page title="Say Hello!">
      <div>
        <p>Contact us by doing XYZ</p>
      </div>
    </Page>
  );
};
