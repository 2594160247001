import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Page } from "../general/Page";
import { getTitleFromId } from "./ProjectDetail";
import { loadTagJson, TagId, tagIds } from "./Tag";
import { TagList } from "./TagList";
import "./ProjectsPage.css";
const queryString = require("query-string");

const projects: string[] = ["test_article", "test_article_2"];

const getProjectsByTag = (
  tagsByProject: Record<string, TagId[]>
): Record<TagId, string[]> => {
  const projectsByTag = {} as Record<TagId, string[]>;
  tagIds.forEach((id) => {
    projectsByTag[id] = projects.filter((_) => tagsByProject[_].includes(id));
  });
  return projectsByTag;
};

export const Projects = () => {
  const { search } = useLocation();
  const [projectTags, setProjectTags] = useState<Record<string, TagId[]>>();
  const [tagProjects, setTagProjects] = useState<Record<TagId, string[]>>();
  const [loadingTags, setLoadingTags] = useState<boolean>(false);

  if (!loadingTags && (!projectTags || !tagProjects)) {
    setLoadingTags(true);
    loadTagJson((projectTags) => {
      setProjectTags(projectTags);
      setTagProjects(getProjectsByTag(projectTags));
      setLoadingTags(false);
    });
  }

  const queryParams = queryString.parse(search);
  const tag: TagId = queryParams.tag;

  const filteredProjects = !tag || !tagProjects ? projects : tagProjects![tag];

  return (
    <Page title="Projects" loading={loadingTags}>
      <TagList
        tagIds={tagIds}
        selectedTagId={tag}
        message="Choose a category to filter down the project list"
      />
      <div className="Projects-Content">
        <ul>
          {filteredProjects.map((_, ndx) => (
            <li>
              <Link key={ndx} to={`/projects/${_}`}>
                {getTitleFromId(_)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Page>
  );
};
