import { Link } from "react-router-dom";
import { Tag as RSuiteTag } from "rsuite";

import "./Tag.css";

export type TagId = "aws" | "rpi" | "esp" | "arduino";

export interface TagProps {
  id: TagId;
  selected?: boolean;
}

interface TagConfig {
  name: string;
  color: any;
}

export const tags: Record<TagId, TagConfig> = {
  rpi: {
    name: "Raspberry Pi",
    color: "red",
  },
  arduino: {
    name: "Arduino",
    color: "green",
  },
  esp: {
    name: "ESP8266 / ESP32",
    color: "blue",
  },
  aws: {
    name: "AWS",
    color: "orange",
  },
};

export const tagIds: TagId[] = (Object.keys(tags) as TagId[]).sort();

export const loadTagJson = (
  callback: (tags: Record<string, TagId[]>) => any
) => {
  fetch("/projects/tags.json")
    .then((response) => response.text())
    .then((content) => {
      console.log("Done loading tags.json");
      callback(JSON.parse(content));
    });
};

export const Tag = (props: TagProps) => {
  return (
    <RSuiteTag
      size={props.selected ? "lg" : "lg"}
      color={tags[props.id].color}
      className={props.selected ? "Tag Tag-Selected" : "Tag Tag-Deselected"}
    >
      <Link to={props.selected ? "/projects" : `/projects?tag=${props.id}`}>
        {tags[props.id].name}
      </Link>
    </RSuiteTag>
  );
};
