import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Remarkable } from "remarkable";
import { Loader } from "rsuite";
import { Page } from "../general/Page";
import { startCase, toLower } from "lodash";
import { TagList } from "./TagList";
import { loadTagJson, TagId } from "./Tag";

const md = new Remarkable();

export const getTitleFromId = (id: string) => {
  return startCase(toLower(id.split("_").join(" ")));
};

export const ProjectDetail = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const [content, setContent] = useState<string>();
  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const [tags, setTags] = useState<TagId[]>();
  const [loadingTags, setLoadingTags] = useState<boolean>(false);

  const path = `/projects/${projectId}.md`;
  console.log(`Loading content from: ${path}`);
  if (!loadingContent && !content) {
    setLoadingContent(true);
    fetch(path)
      .then((response) => response.text())
      .then((markdown) => {
        console.log("Done loading project content");
        // article doesn't exist
        if (markdown.includes("<title>React App</title>")) {
          console.log(`Project "${projectId}" doesn't exist`);
          history.push("/projects");
        } else {
          setContent(markdown);
          setLoadingContent(false);
        }
      });
  }
  if (!loadingTags && !tags) {
    setLoadingTags(true);
    loadTagJson((tags) => {
      console.log("Done loading project tags");
      setTags(tags[projectId] || []);
      setLoadingTags(false);
    });
  }

  const tagList = (
    <TagList tagIds={tags || []} message="Check out other similar projects" />
  );

  return (
    <Page title={getTitleFromId(projectId)}>
      <div className="Project-Details">
        {loadingContent || loadingTags ? (
          <Loader />
        ) : (
          <div>
            {tags ? tagList : <div />}
            <div
              className="Project-Content"
              dangerouslySetInnerHTML={{
                __html: md.render(content || ""),
              }}
            />
          </div>
        )}
      </div>
    </Page>
  );
};
