import { Loader } from "rsuite";
import "./Page.css";

export interface PageProps {
  title: string;
  children: any;
  loading?: boolean;
}

export const Page = (props: PageProps) => {
  return (
    <div className="App-Page">
      <h1 className="Page-Title">{props.title}</h1>
      <div className="Page-Content">
        {props.loading ? <Loader /> : props.children}
      </div>
    </div>
  );
};
