import { Page } from "../general/Page";

export const NotFoundPage = () => {
  return (
    <Page title="404">
      <div>
        <p>Uh oh!</p>
      </div>
    </Page>
  );
};
