import { Page } from "../general/Page";

export const About = () => {
  return (
    <Page title="About">
      <div>
        <p>About us</p>
      </div>
    </Page>
  );
};
